<template>
  <div class="main">
    <p>
      <span style="font-family: 宋体; font-size: 14px">用户协议</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">感谢您使用</span
        ><span style="font-family: Calibri">星科AI</span
        ><span style="font-family: 宋体">。</span
        ><span style="font-family: Calibri">星科AI</span
        ><span style="font-family: 宋体">是由北京</span></span
      ><span style="font-family: 宋体; font-size: 14px"
        >星润时代网络科技有限公司</span
      ><span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体"
          >为您提供的一款社交问答工具类产品。本协议为您与本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >管理者之间所订立的契约，具有合同的法律效力，请您仔细阅读。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">一、免责声明</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">1.1 </span
        ><span style="font-family: 宋体"
          >使用本服务时请您确保您所输入的内容未侵害他人权益，未涉及不良信息及情色内容，同时未输入与政治相关的内容，且所有输入内容均合法合规。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">1.2 </span
        ><span style="font-family: 宋体">若平台发现您故意输入</span
        ><span style="font-family: Calibri">1.1</span
        ><span style="font-family: 宋体">中所含的内容，引导</span
        ><span style="font-family: Calibri">AI</span
        ><span style="font-family: 宋体"
          >生成不良内容并进行传播造谣，对公司造成影响，我们有权对您的账号进行永久封禁并追究相关法律责任。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">1.3 </span
        ><span style="font-family: 宋体"
          >您确认并知悉当前体验服务生成的所有内容均由人工智能模型生成，</span
        ><span style="font-family: Calibri">星科AI</span
        ><span style="font-family: 宋体"
          >对其生成内容的准确性、完整性和功能性不做任何保证，并且其生成的内容均不代表我们的态度或观点。</span
        ><span style="font-family: Calibri">AI</span
        ><span style="font-family: 宋体"
          >模型发出的信息是由计算机生成，可能是虚构的，也请您自行辨别是非。您在使用本应用咨询医疗、法律等相关问题时，本应用仅提供医疗建议和法律建议，不代表医疗服务法律服务或治疗。您在使用本应用前必须咨询医生和相关法律人士，对医疗和法律决定负最终责任。本应用的信息及服务应仅供参考，不构成医疗建议法律建议和治疗方式，我们不承担由使用本应用而产生的任何责任。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">1.4 </span
        ><span style="font-family: 宋体"
          >我们的服务来自于法律法规允许的范围，包括但不限于公开互联网等信息积累，并已经过不断的自动及人工敏感数据过滤，但仍不排除其中部分信息具有瑕疵、不合理或引发不快的问题存在。如遇相关情形，欢迎您随时通过本平台的邮箱</span
        ><span style="font-family: Calibri">(</span></span
      ><span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">lidegeng</span></span
      ><span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">@xingrunkeji.cn) </span
        ><span style="font-family: 宋体"
          >向我们进行反馈，我们将对您的意见进行高度重视并采取及时有效的处理措施，同时对您表示十分感谢。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">1.5</span
        ><span style="font-family: 宋体"
          >您在同意接受本声明之前，具体的使用规范请严格按照《用户协议》的内容进行输入。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">1.6</span
        ><span style="font-family: 宋体"
          >无论您是否已实际阅读本声明，当您通过网络页面点击确认同意本声明或实际使用本平台（“我方”）提供的本服务时，均表示您与我方已就本声明达成一致并同意接受本声明的全部约定内容。如果您不同意本声明的任意内容，或者无法准确理解我方对本声明条款的解释，请点击不同意或停止使用本服务。否则即表示您已接受本申明所述的所有条款及其适用条件，同意受本声明约束。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">0</span></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">1.7</span
        ><span style="font-family: 宋体"
          >我们尊重并保护您及所有使用本服务用户的个人隐私，在此提醒您：请您确认使用本服务时输入的内容将不包含您本人及他人的个人信息。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">用户协议</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >二、本协议内容、生效、变更</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">本协议内容包括协议正文及所有本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。如您对协议有任何疑问，应向本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >咨询。您在同意所有协议条款并完成注册程序，才能成为本站的正式用户，您点击“同意并接受”按钮后，本协议即生效，对双方产生约束力。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体">只要您使用本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >平台服务，则本协议即对您产生约束，届时您不应以未阅读本协议的内容或者未获得本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >对您问询的解答等理由，主张本协议无效，或要求撤销本协议。您确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止注册程序或停止使用本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体">平台服务。本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >有权根据需要不定期地制订、修改本协议及／或各类规则，并在本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >平台公示，不再另行单独通知用户。变更后的协议和规则一经在网站公布，立即生效。如您不同意相关变更，应当立即停止使用本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体">平台服务。您继续使用本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >平台服务的，即表明您接受修订后的协议和规则。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">二、注册</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: 宋体"
          >注册资格用户须具有法定的相应权利能力和行为能力的自然人、法人或其他组织，能够独立承担法律责任。您完成注册程序或其他本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >平台同意的方式实际使用本平台服务时，即视为您确认自己具备主体资格，能够独立承担法律责任。若因您不具备主体资格，而导致的一切后果，由您及您的监护人自行承担。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">2.1</span
        ><span style="font-family: 宋体"
          >用户应自行诚信向本站提供注册资料，用户同意其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >保留终止用户使用本平台各项服务的权利。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">2.2</span
        ><span style="font-family: 宋体"
          >用户在本站进行浏览等活动时，如果涉及用户真实姓名</span
        ><span style="font-family: Calibri">/</span
        ><span style="font-family: 宋体"
          >名称、通信地址、联系电话、电子邮箱等隐私信息的，本站将予以严格保密，除非得到用户的授权或律另有规定，本站不会向外界披露用户隐私信息。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">三、账户</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">3.1</span
        ><span style="font-family: 宋体">您使用微信登录或者设备登录（</span
        ><span style="font-family: Calibri">ios</span
        ><span style="font-family: 宋体">）注册成功后，将持有本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体">平台唯一编号的账户信息。</span></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">3.2</span
        ><span style="font-family: 宋体"
          >您应谨慎合理的保存、使用您的登录账户信息，应对通过您的账户信息实施的行为负责。除非有法律规定或司法裁定，且征得本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >的同意，否则，用户信息不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）。用户不得将在本站注册获得的账户借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">3.3</span
        ><span style="font-family: 宋体"
          >如果发现任何非法使用等可能危及您的账户安全的情形时，您应当立即以有效方式通知本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >要求暂停相关服务，并向公安机关报案。您理解本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >对您的请求采取行动需要合理时间，本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        >四、用户信息的合理使用</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">4.1</span
        ><span style="font-family: 宋体">您了解并同意，本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >有权应国家司法、行政等主管部门的要求，向其提供您在本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >平台填写的注册信息和交易记录等必要信息。如您涉嫌侵犯他人知识产权，则本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >亦有权在初步判断涉嫌侵权行为存在的情况下，向权利人提供您必要的身份信息。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">4.2</span
        ><span style="font-family: 宋体">用户同意本</span
        ><span style="font-family: Calibri">APP</span
        ><span style="font-family: 宋体"
          >有权使用用户的注册信息、用户名等信息，登陆进入用户的注册账户，进行证据保全，包括但不限于公证、见证等。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">五、服务使用协议</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">5.1</span
        ><span style="font-family: 宋体"
          >您承诺将严格依照本声明使用本服务，并且不会利用本服务进行任何违法或不当的活动，发布、传送或分享含有下列内容之一的信息：</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">(1) </span
        ><span style="font-family: 宋体"
          >反对宪法所确定的基本原则的；</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">(2) </span
        ><span style="font-family: 宋体"
          >危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">(3) </span
        ><span style="font-family: 宋体">损害国家荣誉和利益的；</span></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">(4) </span
        ><span style="font-family: 宋体"
          >煽动民族仇恨、民族歧视、破坏民族团结的；</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">(5) </span
        ><span style="font-family: 宋体"
          >破坏国家宗教政策，宣扬邪教和封建迷信的；</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">(6) </span
        ><span style="font-family: 宋体"
          >散布谣言，扰乱社会秩序，破坏社会稳定的；</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">(7) </span
        ><span style="font-family: 宋体"
          >散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">(8) </span
        ><span style="font-family: 宋体"
          >侮辱或者诽谤他人，侵害他人合法权利的；</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">(9) </span
        ><span style="font-family: 宋体"
          >含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">(10) </span
        ><span style="font-family: 宋体"
          >含有中国或其您所在国国家管辖法所适用的法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。
          您应当对使用本服务是否符合法律法规规定进行必要审查，并由您承担由此产生的所有责任。您确认并同意，我方不会因为本服务或您使用本服务违反上述约定，而需要承担任何责任。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">5.2</span
        ><span style="font-family: 宋体"
          >您应当对使用本服务是否符合法律法规的相关规定进行必要审查，您本人将承担由此产生的所有责任。您确认并同意，我方不会因为本服务或您使用本服务违反上述约定，而需要承担任何责任。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">5.3</span
        ><span style="font-family: 宋体"
          >您使用本服务时不得侵害他人权益（包括但不限于著作权、专利权、商标权、肖像权等知识产权与其他权益）。同时您同意并承诺，您使用本服务所提供、使用的文本内容已获得了充分、必要且有效的合法许可及授权。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">5.4</span
        ><span style="font-family: 宋体"
          >您同意并承诺在使用本服务时，不会披露任何保密、敏感或个人隐私等信息。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">5.5</span
        ><span style="font-family: 宋体"
          >您理解并同意，本服务仅处于体验、试用阶段，尚存在不完备性，我方不对任何服务可用性、可靠性做出任何承诺。我方不对您使用本服务或本服务结果承担任何责任，且本服务结果不代表我方立场。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">5.6</span
        ><span style="font-family: 宋体"
          >我方有权因为业务发展或法律法规的变动而随时对本服务的内容和</span
        ><span style="font-family: Calibri">/</span
        ><span style="font-family: 宋体"
          >或提供方式进行变动，也可暂停或终止本服务。您同意我方将不对因上述情况导致的任何后果而向您或第三方承担任何责任。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">5.7</span
        ><span style="font-family: 宋体"
          >在任何情况下，我方均不会因本服务所发生的任何直接性、间接性、后果性、惩戒性、偶然性、特殊性的损害</span
        ><span style="font-family: Calibri">(</span
        ><span style="font-family: 宋体"
          >包括但不限于：您使用本服务而遭受的利润损失</span
        ><span style="font-family: Calibri">)</span
        ><span style="font-family: 宋体">，承担任何责任</span
        ><span style="font-family: Calibri">(</span
        ><span style="font-family: 宋体"
          >即使您已事先被告知该等损害发生的可能性</span
        ><span style="font-family: Calibri">)</span
        ><span style="font-family: 宋体">。</span></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">5.8</span
        ><span style="font-family: 宋体"
          >若您违反本声明任何约定，则我方有权随时单方面终止本声明，且我方无需承担任何责任。同时我方有权根据实际损失向您请求赔偿。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">5.9</span
        ><span style="font-family: 宋体"
          >除双方另有约定外，本声明之解释与适用以及与本声明有关的争议，均依照中华人民共和国法律予以处理</span
        ><span style="font-family: Calibri">(</span
        ><span style="font-family: 宋体">不包含冲突法</span
        ><span style="font-family: Calibri">)</span
        ><span style="font-family: 宋体"
          >，并由我方所在地有管辖权的人民法院管辖。</span
        ></span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">&nbsp;</span>
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px">北京</span
      ><span style="font-family: 宋体; font-size: 14px"
        >星润时代网络科技有限公司</span
      >
    </p>
    <p>
      <span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">2023</span
        ><span style="font-family: 宋体">年</span></span
      ><span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">5</span></span
      ><span style="font-family: 宋体; font-size: 14px">月</span
      ><span style="font-family: 宋体; font-size: 14px"
        ><span style="font-family: Calibri">8</span></span
      ><span style="font-family: 宋体; font-size: 14px">日</span>
    </p>
    <p>
      <br />
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style >
.main {
  padding: 20px;
}
</style>